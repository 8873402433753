var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "check-box-tbl",
      on: {
        click: function ($event) {
          $event.stopPropagation()
        },
      },
    },
    [
      _c("checkbox", {
        attrs: { name: "cb" + _vm.data.index },
        model: {
          value: _vm.data.check[_vm.data.index],
          callback: function ($$v) {
            _vm.$set(_vm.data.check, _vm.data.index, $$v)
          },
          expression: "data.check[data.index]",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }